import { Select } from '@/styles/generic.styles'
import { SelectItem } from '@/types/General'
import { FC, useContext, useState } from 'react'
import { SettingsButton, SectionTitle } from '../Settings.styles'
import { STAGE, STAGES } from '@/constants/envs'
import { SettingComponent } from '../SettingComponent'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'

export const EnvironmentSelector: FC = observer(() => {
  const store = useContext(StoreContext)
  const { experimentalFeatures } = store.AppState

  const [selectedStageValue, setSelectedStageValue] = useState<SelectItem | null>({
    label: 'Default',
    value: STAGE,
  })

  return (
    <SettingComponent visible={experimentalFeatures}>
      <SectionTitle>Selected environment:</SectionTitle>
      <Select
        options={STAGES.map((stage) => ({ label: stage, value: stage }))}
        onChange={(val: SelectItem) => setSelectedStageValue(val)}
        value={selectedStageValue}
      />
      <SettingsButton
        onClick={() => {
          if (!selectedStageValue?.value) return
          localStorage.setItem('stage', selectedStageValue.value as string)
          window.location.reload()
        }}
      >
        Apply & reload
      </SettingsButton>
    </SettingComponent>
  )
})
