export const getUsers = {
  users: [
    {
      id: 'b1cb5442-95c4-42e7-83c9-8c94beffb783',
      name: 'Dawid Gorczyca',
      email: 'dawgor@signicat.com',
    },
    {
      id: '5b1bf2f1-2c9b-489c-9197-e5d3d261aaf3',
      name: 'Igor Kaliciński',
      email: 'igokal@signicat.com',
      entraUserId: 'a208cf5c-8d55-4f1c-aba3-dfe473c70178',
    },
    {
      id: '6182389c-ed94-44d5-9f85-9e63add33a38',
      name: 'Paweł Jurusz',
      email: 'pawjur@signicat.com',
      entraUserId: '091bc972-3602-412b-acb4-9c977a73f7d5',
      isDeleted: false,
    },
    {
      id: '2fec2cd4-0fb5-4e9e-9fe3-f2a60c7d238d',
      name: 'Krzysztof Tucholski',
      email: 'krztuc@signicat.com',
      entraUserId: '11152513-701e-4ac2-b32f-9c6e6e7dff31',
    },
  ],
}

export const getCurrentUser = {
  userId: '5b1bf2f1-2c9b-489c-9197-e5d3d261aaf3',
  userName: 'User Name',
  email: 'user@signicat.com',
  capabilities: ['ACCESS_MANAGEMENT', 'CLIENT_CONFIG_MANAGEMENT', 'INCIDENT_MANAGEMENT'],
}

export const getEntityList = [
  {
    name: 'pixelSpeak.live.ikano',
  },
  {
    name: 'klarna.dtp-openbanking-live',
  },
  {
    name: 'eid.nationalIdValidation',
  },
  {
    name: 'eid.sandbox',
  },
  {
    name: 'klarna-payment-settings-e2e',
  },
  {
    name: 'kontomatik.live',
  },
  {
    name: 'klarna.live',
  },
  {
    name: 'kontomatik.sandbox',
  },
  {
    name: 'tink.sandbox.mock',
  },
  {
    name: 'klarna.sandbox',
  },
  {
    name: 'tink.sandbox',
  },
  {
    name: 'klarna-payment-settings',
  },
  {
    name: 'tink.live-ikano-report',
  },
  {
    name: 'tink.live-test',
  },
  {
    name: 'eid.uat.live',
  },
  {
    name: 'eid.dev.sandbox',
  },
  {
    name: 'tink.live-reports',
  },
  {
    name: 'pixelSpeak.sandbox',
  },
  {
    name: 'tink.live',
  },
  {
    name: 'klarna.e2e',
  },
  {
    name: 'kontomatik.prod',
  },
  {
    name: 'tink.e2e',
  },
]

export const getClientList = [
  {
    name: 'sandbox.demo',
  },
  {
    name: 'internal-sandbox.ikano',
  },
  {
    name: 'prod.pink',
  },
  {
    name: 'live.banknorwegian',
  },
  {
    name: 'uat.pink',
  },
  {
    name: 'internal-uat.ikano',
  },
  {
    name: 'internal-uat.bmw',
  },
  {
    name: 'sandbox.ikano',
  },
  {
    name: 'sandbox.arkwright',
  },
  {
    name: 'live.ikano',
  },
  {
    name: 'sandbox.assure',
  },
  {
    name: 'live.bmw',
  },
  {
    name: 'sandbox.banknorwegian',
  },
  {
    name: 'sandbox.bmw-e2e',
  },
  {
    name: 'dtp-live.pink',
  },
  {
    name: 'sandbox.jitpay',
  },
  {
    name: 'sandbox.sales',
  },
  {
    name: 'sandbox.levels',
  },
  {
    name: 'internal-prod.electronicId',
  },
  {
    name: 'live.jitpay',
  },
  {
    name: 'sandbox.bmw',
  },
  {
    name: 'internal-demo.onboarding-amex-proxy',
  },
  {
    name: 'sandbox.rafal',
  },
  {
    name: 'sandbox.cmc',
  },
  {
    name: 'live.rafal',
  },
  {
    name: 'internal-sandbox.bmw',
  },
  {
    name: 'internal-dev',
  },
  {
    name: 'moritz-prod.electronicId',
  },
  {
    name: 'internal-demo.signicat',
  },
  {
    name: 'internal-live.ikano',
  },
  {
    name: 'dev.tink-live-reports',
  },
  {
    name: 'e2e.onboarding',
  },
  {
    name: 'sandbox.bmw-int',
  },
  {
    name: 'sandbox.cmc-2',
  },
  {
    name: 'sandbox.bmw-preprod',
  },
  {
    name: 'sandbox.phantom-key',
  },
  {
    name: 'dev.pink',
  },
  {
    name: 'demo.pink',
  },
  {
    name: 'uat.ikano',
  },
  {
    name: 'dtp-dev.pink',
  },
  {
    name: 'sandbox.bmw-fsm',
  },
]

export const getEntity = {
  current: {
    clientId: 'onboarding.dev.pink',
    type: 'Entity',
    goToMobileConfiguration: {
      sms: {
        maxAttempts: 3,
        senderId: 'Signicat',
        message: 'Continue by clicking the link',
        retrySeconds: 60,
        enabled: true,
      },
      supportedServiceTypes: ['eidv', 'addressVerification', 'micropayment'],
      enableDesktopPolling: true,
      enabled: true,
      pinCode: {
        maxAttempts: 3,
      },
    },
  },
  versions: [
    {
      version: 14,
      clientId: 'onboarding.dev.pink:14',
      type: 'Entity',
      goToMobileConfiguration: {
        sms: {
          maxAttempts: 3,
          senderId: 'Signicat',
          message: 'Continue by clicking the link',
          retrySeconds: 60,
          enabled: true,
        },
        supportedServiceTypes: ['eidv', 'addressVerification', 'micropayment'],
        enableDesktopPolling: true,
        enabled: true,
        pinCode: {
          maxAttempts: 3,
        },
      },
    },
  ],
  resolved: {
    clientId: 'onboarding.dev.pink',
    type: 'Entity',
    goToMobileConfiguration: {
      sms: {
        maxAttempts: 3,
        senderId: 'Signicat',
        message: 'Continue by clicking the link',
        retrySeconds: 60,
        enabled: true,
      },
      supportedServiceTypes: ['eidv', 'addressVerification', 'micropayment'],
      enableDesktopPolling: true,
      enabled: true,
      pinCode: {
        maxAttempts: 3,
      },
    },
  },
  isValidationSuccess: true,
  validationErrors: [],
  references: ['dev.pink', 'dev.pink.asdfasdfasdfasdfasdfasdfasdfasdfasdfasdfadfas'],
  referenceMapping: [],
  secretReferences: {},
}

export const getClient = {
  current: {
    responseType: 'plain',
    encryptionKid: 'test.common.jwk.v.1',
    resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
    enableAccessCode: true,
    'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
    clientId: 'dtp-dev.pink',
    type: 'Client',
    services: {
      'klarna-ref': {
        '@default': ['klarna-payment-settings', 'klarna.sandbox'],
      },
      'tink-ref': {
        '@default': ['tink.sandbox'],
      },
      'kontomatik-ref': {
        '@default': ['kontomatik.sandbox'],
      },
    },
    balance: '$2,984.76',
    picture: 'http://placehold.it/32x32',
    age: 27,
    eyeColor: 'blue',
    gender: 'female',
    company: 'SCHOOLIO',
    email: 'jewelhaley@schoolio.com',
    phone: '+1 (914) 425-2589',
    address: '791 Winthrop Street, Chumuckla, Idaho, 1856',
    about:
      'Aliquip adipisicing sit occaecat dolore mollit. Proident duis sint laboris reprehenderit deserunt esse fugiat dolor proident. Proident proident elit sint sit fugiat excepteur tempor proident elit. Ullamco do ullamco duis ullamco. Qui esse aute fugiat nisi ea adipisicing cillum do non reprehenderit. Nostrud qui reprehenderit quis fugiat fugiat dolor exercitation consectetur sit duis voluptate eiusmod fugiat cillum.\r\n',
    registered: '2016-08-31T06:48:42 -02:00',
    latitude: 23.712701,
    longitude: 177.524778,
    tags: ['ea', 'magna', 'consequat', 'in', 'aliquip', 'occaecat', 'in'],
    friends: [
      {
        id: 0,
        name: 'Berry Shaw',
      },
      {
        id: 1,
        name: 'Mayer Butler',
      },
      {
        id: 2,
        name: 'English Cohen',
      },
      {
        id: 3,
        name: 'Walls Rosario',
      },
      {
        id: 4,
        name: 'Madelyn Tyson',
      },
      {
        id: 5,
        name: 'Joy Wynn',
      },
      {
        id: 6,
        name: 'Torres Weeks',
      },
      {
        id: 7,
        name: 'Lindsey Mooney',
      },
      {
        id: 8,
        name: 'Cleo Reese',
      },
      {
        id: 9,
        name: 'Maryanne Hobbs',
      },
      {
        id: 10,
        name: 'Jo Callahan',
      },
      {
        id: 11,
        name: 'Stacy Clark',
      },
      {
        id: 12,
        name: 'Annette Patel',
      },
      {
        id: 13,
        name: 'Coleman Ramos',
      },
      {
        id: 14,
        name: 'Socorro Gill',
      },
      {
        id: 15,
        name: 'Marie Pitts',
      },
      {
        id: 16,
        name: 'Bernard Francis',
      },
      {
        id: 17,
        name: 'Cooper Schroeder',
      },
      {
        id: 18,
        name: 'Trudy Barrera',
      },
      {
        id: 19,
        name: 'Alisha Shaffer',
      },
      {
        id: 20,
        name: 'Craft Puckett',
      },
      {
        id: 21,
        name: 'Kidd Herrera',
      },
      {
        id: 22,
        name: 'Shari Jensen',
      },
      {
        id: 23,
        name: 'Earlene Black',
      },
      {
        id: 24,
        name: 'Mae Soto',
      },
      {
        id: 25,
        name: 'Fowler Strickland',
      },
      {
        id: 26,
        name: 'Deana Bailey',
      },
      {
        id: 27,
        name: 'Minnie Holloway',
      },
      {
        id: 28,
        name: 'Lyons Harper',
      },
      {
        id: 29,
        name: 'Myers Britt',
      },
      {
        id: 30,
        name: 'Hunter Serrano',
      },
      {
        id: 31,
        name: 'Connie Rose',
      },
      {
        id: 32,
        name: 'Williamson Cameron',
      },
      {
        id: 33,
        name: 'Mccarty Peck',
      },
      {
        id: 34,
        name: 'Good Potts',
      },
      {
        id: 35,
        name: 'Susanne Schmidt',
      },
      {
        id: 36,
        name: 'Peters Ross',
      },
      {
        id: 37,
        name: 'Walters Mcleod',
      },
      {
        id: 38,
        name: 'Navarro Myers',
      },
      {
        id: 39,
        name: 'Delia Mckay',
      },
      {
        id: 40,
        name: 'Corine Summers',
      },
      {
        id: 41,
        name: 'Selena Livingston',
      },
      {
        id: 42,
        name: 'Lizzie Jackson',
      },
      {
        id: 43,
        name: 'Castro Franks',
      },
      {
        id: 44,
        name: 'Summers Rodgers',
      },
      {
        id: 45,
        name: 'Bernadine Boyd',
      },
      {
        id: 46,
        name: 'Freida Joyce',
      },
      {
        id: 47,
        name: 'Lila Huber',
      },
      {
        id: 48,
        name: 'Yvette Fuller',
      },
      {
        id: 49,
        name: 'Lancaster Perez',
      },
      {
        id: 50,
        name: 'Jane Rivera',
      },
      {
        id: 51,
        name: 'Bobbie Prince',
      },
      {
        id: 52,
        name: 'Wolfe Cross',
      },
      {
        id: 53,
        name: 'Mills Stokes',
      },
      {
        id: 54,
        name: 'Jensen Murphy',
      },
      {
        id: 55,
        name: 'Ward Wells',
      },
      {
        id: 56,
        name: 'Berg White',
      },
      {
        id: 57,
        name: 'Alissa Bullock',
      },
      {
        id: 58,
        name: 'Fitzpatrick Campbell',
      },
      {
        id: 59,
        name: 'Eve Stuart',
      },
      {
        id: 60,
        name: 'Gina Smith',
      },
      {
        id: 61,
        name: 'Caldwell Burnett',
      },
      {
        id: 62,
        name: 'Cora Contreras',
      },
      {
        id: 63,
        name: 'Alexandra Gordon',
      },
      {
        id: 64,
        name: 'Randolph Noble',
      },
      {
        id: 65,
        name: 'Paul Gates',
      },
      {
        id: 66,
        name: 'Helena Richard',
      },
      {
        id: 67,
        name: 'Langley Pollard',
      },
      {
        id: 68,
        name: 'Mona Wright',
      },
      {
        id: 69,
        name: 'Brewer Osborn',
      },
      {
        id: 70,
        name: 'Benita Owen',
      },
      {
        id: 71,
        name: 'Paige Dalton',
      },
      {
        id: 72,
        name: 'Graves Wiley',
      },
      {
        id: 73,
        name: 'Clay Bennett',
      },
      {
        id: 74,
        name: 'Kellie Haney',
      },
      {
        id: 75,
        name: 'Buckner Mckee',
      },
      {
        id: 76,
        name: 'Casandra Pratt',
      },
      {
        id: 77,
        name: 'Lily Edwards',
      },
      {
        id: 78,
        name: 'Billie Palmer',
      },
      {
        id: 79,
        name: 'Lori Farrell',
      },
      {
        id: 80,
        name: 'Owens Hall',
      },
      {
        id: 81,
        name: 'Potts Kaufman',
      },
      {
        id: 82,
        name: 'Parsons Barr',
      },
      {
        id: 83,
        name: 'Hardin Herman',
      },
      {
        id: 84,
        name: 'Marina Estes',
      },
      {
        id: 85,
        name: 'Audra Andrews',
      },
      {
        id: 86,
        name: 'Elba Mclaughlin',
      },
      {
        id: 87,
        name: 'Whitney Barber',
      },
      {
        id: 88,
        name: 'Thomas Clay',
      },
      {
        id: 89,
        name: 'Lowery Solomon',
      },
      {
        id: 90,
        name: 'Horne Kelley',
      },
      {
        id: 91,
        name: 'Robertson Poole',
      },
      {
        id: 92,
        name: 'Cooley Wilcox',
      },
      {
        id: 93,
        name: 'Estelle Fletcher',
      },
      {
        id: 94,
        name: 'Barlow Blanchard',
      },
      {
        id: 95,
        name: 'Strickland Ray',
      },
      {
        id: 96,
        name: 'Lisa Silva',
      },
      {
        id: 97,
        name: 'Blevins Maynard',
      },
      {
        id: 98,
        name: 'Moreno Hickman',
      },
      {
        id: 99,
        name: 'Julia Cannon',
      },
      {
        id: 100,
        name: 'Eugenia Morrison',
      },
      {
        id: 101,
        name: 'Carrie Wooten',
      },
      {
        id: 102,
        name: 'Mcmillan Atkins',
      },
      {
        id: 103,
        name: 'Phoebe Madden',
      },
      {
        id: 104,
        name: 'Traci Walter',
      },
      {
        id: 105,
        name: 'Ola Franco',
      },
      {
        id: 106,
        name: 'Clarke King',
      },
      {
        id: 107,
        name: 'Hodges Russo',
      },
      {
        id: 108,
        name: 'Leon Douglas',
      },
      {
        id: 109,
        name: 'Glenna Jordan',
      },
      {
        id: 110,
        name: 'Mcknight Walsh',
      },
      {
        id: 111,
        name: 'Dana Burke',
      },
      {
        id: 112,
        name: 'Candace Oneil',
      },
      {
        id: 113,
        name: 'Lara Montgomery',
      },
      {
        id: 114,
        name: 'Millicent Hess',
      },
      {
        id: 115,
        name: 'George Adkins',
      },
      {
        id: 116,
        name: 'Tamera Walton',
      },
      {
        id: 117,
        name: 'Santiago Vega',
      },
      {
        id: 118,
        name: 'Maryellen Whitehead',
      },
      {
        id: 119,
        name: 'Odom Lancaster',
      },
      {
        id: 120,
        name: 'Spence Barnes',
      },
      {
        id: 121,
        name: 'Salinas Ruiz',
      },
      {
        id: 122,
        name: 'Rochelle Watson',
      },
      {
        id: 123,
        name: 'Goodwin Williams',
      },
      {
        id: 124,
        name: 'Bass Drake',
      },
      {
        id: 125,
        name: 'Lott Lindsey',
      },
      {
        id: 126,
        name: 'Fitzgerald Albert',
      },
      {
        id: 127,
        name: 'Tamra Mccarty',
      },
      {
        id: 128,
        name: 'Brianna Howard',
      },
      {
        id: 129,
        name: 'Mercedes Kent',
      },
      {
        id: 130,
        name: 'Garza Dunlap',
      },
      {
        id: 131,
        name: 'Hines Rasmussen',
      },
      {
        id: 132,
        name: 'Laurie Petersen',
      },
      {
        id: 133,
        name: 'Hurst Nielsen',
      },
      {
        id: 134,
        name: 'Francis Garcia',
      },
      {
        id: 135,
        name: 'Loretta Fields',
      },
      {
        id: 136,
        name: 'Erica Quinn',
      },
      {
        id: 137,
        name: 'Sondra Cervantes',
      },
      {
        id: 138,
        name: 'Shepard Velasquez',
      },
      {
        id: 139,
        name: 'Andrea Morrow',
      },
      {
        id: 140,
        name: 'Phyllis Whitney',
      },
      {
        id: 141,
        name: 'Josie Scott',
      },
      {
        id: 142,
        name: 'Neal Pugh',
      },
      {
        id: 143,
        name: 'Tommie Hamilton',
      },
      {
        id: 144,
        name: 'Aisha Pruitt',
      },
      {
        id: 145,
        name: 'Robyn Knox',
      },
      {
        id: 146,
        name: 'Moon Bond',
      },
      {
        id: 147,
        name: 'Best May',
      },
      {
        id: 148,
        name: 'Oliver Robertson',
      },
      {
        id: 149,
        name: 'Gamble Dickson',
      },
      {
        id: 150,
        name: 'Walker Emerson',
      },
      {
        id: 151,
        name: 'Mullins Lawrence',
      },
      {
        id: 152,
        name: 'Dina Watkins',
      },
      {
        id: 153,
        name: 'Johns Leach',
      },
      {
        id: 154,
        name: 'Milagros Rowland',
      },
      {
        id: 155,
        name: 'Mcfarland Alford',
      },
      {
        id: 156,
        name: 'Charmaine Kim',
      },
      {
        id: 157,
        name: 'Harriet Garrett',
      },
      {
        id: 158,
        name: 'Colette Cotton',
      },
      {
        id: 159,
        name: 'Lucinda Sexton',
      },
      {
        id: 160,
        name: 'Christian Horne',
      },
      {
        id: 161,
        name: 'Nolan Bird',
      },
      {
        id: 162,
        name: 'Lucas Keller',
      },
      {
        id: 163,
        name: 'Cote Cantrell',
      },
      {
        id: 164,
        name: 'Dawn Mcpherson',
      },
      {
        id: 165,
        name: 'Barnes Weber',
      },
      {
        id: 166,
        name: 'Britney Powers',
      },
      {
        id: 167,
        name: 'Tracey Bradshaw',
      },
      {
        id: 168,
        name: 'Rice Salazar',
      },
      {
        id: 169,
        name: 'Richards Sampson',
      },
      {
        id: 170,
        name: 'Harmon Cortez',
      },
      {
        id: 171,
        name: 'Love Guzman',
      },
      {
        id: 172,
        name: 'Cindy Richmond',
      },
      {
        id: 173,
        name: 'Allie Rosales',
      },
      {
        id: 174,
        name: 'Castaneda Kirby',
      },
      {
        id: 175,
        name: 'Vasquez Morgan',
      },
      {
        id: 176,
        name: 'Parks Reilly',
      },
      {
        id: 177,
        name: 'Gaines Stevenson',
      },
      {
        id: 178,
        name: 'Ashley Vargas',
      },
      {
        id: 179,
        name: 'Martha Morse',
      },
      {
        id: 180,
        name: 'Desiree Mosley',
      },
      {
        id: 181,
        name: 'Mcdowell Hendricks',
      },
      {
        id: 182,
        name: 'Elsie Henry',
      },
      {
        id: 183,
        name: 'Jordan Burns',
      },
      {
        id: 184,
        name: 'Owen Solis',
      },
      {
        id: 185,
        name: 'Pat Singleton',
      },
      {
        id: 186,
        name: 'Mavis Ayers',
      },
      {
        id: 187,
        name: 'Wilda Hughes',
      },
      {
        id: 188,
        name: 'Chan Bryan',
      },
      {
        id: 189,
        name: 'Miranda Preston',
      },
      {
        id: 190,
        name: 'Twila Rutledge',
      },
      {
        id: 191,
        name: 'Roberts Brennan',
      },
      {
        id: 192,
        name: 'Pam Grimes',
      },
      {
        id: 193,
        name: 'Kathrine Pena',
      },
      {
        id: 194,
        name: 'Rich Sloan',
      },
      {
        id: 195,
        name: 'Ginger Paul',
      },
      {
        id: 196,
        name: 'Debbie Powell',
      },
      {
        id: 197,
        name: 'Eliza Sears',
      },
      {
        id: 198,
        name: 'Fern Riley',
      },
      {
        id: 199,
        name: 'Mitzi Aguirre',
      },
      {
        id: 200,
        name: 'Herman Green',
      },
      {
        id: 201,
        name: 'Powers Caldwell',
      },
      {
        id: 202,
        name: 'Glenn Grant',
      },
      {
        id: 203,
        name: 'Louise Mayo',
      },
      {
        id: 204,
        name: 'Sophia Blake',
      },
      {
        id: 205,
        name: 'Ramos Massey',
      },
      {
        id: 206,
        name: 'Rosalyn Oconnor',
      },
      {
        id: 207,
        name: 'Cecilia Fischer',
      },
      {
        id: 208,
        name: 'Celia Gregory',
      },
      {
        id: 209,
        name: 'Parrish Mason',
      },
      {
        id: 210,
        name: 'Luann Valentine',
      },
      {
        id: 211,
        name: 'Cook Kane',
      },
      {
        id: 212,
        name: 'Caroline Brewer',
      },
      {
        id: 213,
        name: 'Vonda Mendez',
      },
      {
        id: 214,
        name: 'Rosalinda Conway',
      },
      {
        id: 215,
        name: 'Avery Key',
      },
      {
        id: 216,
        name: 'Rowland Dotson',
      },
      {
        id: 217,
        name: 'Hood Fleming',
      },
      {
        id: 218,
        name: 'Maxine Harrell',
      },
      {
        id: 219,
        name: 'Rosa Collins',
      },
      {
        id: 220,
        name: 'Curry Reed',
      },
      {
        id: 221,
        name: 'Vilma Marquez',
      },
      {
        id: 222,
        name: 'Flora Foreman',
      },
      {
        id: 223,
        name: 'Fischer Macias',
      },
      {
        id: 224,
        name: 'Guthrie Deleon',
      },
      {
        id: 225,
        name: 'Petra Cobb',
      },
      {
        id: 226,
        name: 'Fulton Santana',
      },
      {
        id: 227,
        name: 'Cecile Brooks',
      },
      {
        id: 228,
        name: 'Christian Hodges',
      },
      {
        id: 229,
        name: 'Kate Mcdonald',
      },
      {
        id: 230,
        name: 'Debra Hampton',
      },
      {
        id: 231,
        name: 'Ursula Jenkins',
      },
      {
        id: 232,
        name: 'Marcy Jones',
      },
      {
        id: 233,
        name: 'Ophelia Morton',
      },
      {
        id: 234,
        name: 'Clark Wyatt',
      },
      {
        id: 235,
        name: 'Dena Sharpe',
      },
      {
        id: 236,
        name: 'Harrison Turner',
      },
      {
        id: 237,
        name: 'Elisabeth Hood',
      },
      {
        id: 238,
        name: 'Dollie Wolf',
      },
      {
        id: 239,
        name: 'Valdez Cabrera',
      },
      {
        id: 240,
        name: 'Catherine Zimmerman',
      },
      {
        id: 241,
        name: 'Richardson Bauer',
      },
      {
        id: 242,
        name: 'Hodge Cain',
      },
      {
        id: 243,
        name: 'Rivera Mann',
      },
      {
        id: 244,
        name: 'Linda Marsh',
      },
      {
        id: 245,
        name: 'Juanita Gallagher',
      },
      {
        id: 246,
        name: 'Johnnie Riddle',
      },
      {
        id: 247,
        name: 'Sanchez Kinney',
      },
      {
        id: 248,
        name: 'Pauline Brady',
      },
      {
        id: 249,
        name: 'Henderson Gaines',
      },
      {
        id: 250,
        name: 'Felicia William',
      },
      {
        id: 251,
        name: 'Sally Holman',
      },
      {
        id: 252,
        name: 'Pearlie Franklin',
      },
      {
        id: 253,
        name: 'Hudson Daniel',
      },
      {
        id: 254,
        name: 'Dejesus Cochran',
      },
      {
        id: 255,
        name: 'Le Ortiz',
      },
      {
        id: 256,
        name: 'Beverley Wall',
      },
      {
        id: 257,
        name: 'Rene Phelps',
      },
      {
        id: 258,
        name: 'Shirley Glass',
      },
      {
        id: 259,
        name: 'Maritza Bright',
      },
      {
        id: 260,
        name: 'Molly Fowler',
      },
      {
        id: 261,
        name: 'Robin Finley',
      },
      {
        id: 262,
        name: 'Horton Castaneda',
      },
      {
        id: 263,
        name: 'Barry Todd',
      },
      {
        id: 264,
        name: 'Essie Cote',
      },
      {
        id: 265,
        name: 'Livingston Mullins',
      },
      {
        id: 266,
        name: 'Jeannette Kemp',
      },
      {
        id: 267,
        name: 'Candice Combs',
      },
      {
        id: 268,
        name: 'Schwartz Hammond',
      },
      {
        id: 269,
        name: 'Oneil Mcdaniel',
      },
      {
        id: 270,
        name: 'Celeste Dunn',
      },
      {
        id: 271,
        name: 'Dixon Austin',
      },
      {
        id: 272,
        name: 'Chambers Winters',
      },
      {
        id: 273,
        name: 'Sawyer Taylor',
      },
      {
        id: 274,
        name: 'Byers Brown',
      },
      {
        id: 275,
        name: 'Ramsey Thornton',
      },
      {
        id: 276,
        name: 'Sherri Good',
      },
      {
        id: 277,
        name: 'Herminia Curry',
      },
      {
        id: 278,
        name: 'Richard Becker',
      },
      {
        id: 279,
        name: 'Bullock Rush',
      },
      {
        id: 280,
        name: 'Hoover Miller',
      },
      {
        id: 281,
        name: 'Amy Stanley',
      },
      {
        id: 282,
        name: 'Lara Barker',
      },
      {
        id: 283,
        name: 'Margery Hawkins',
      },
      {
        id: 284,
        name: 'Alfreda Higgins',
      },
      {
        id: 285,
        name: 'Blanche Casey',
      },
      {
        id: 286,
        name: 'Hogan Carlson',
      },
      {
        id: 287,
        name: 'Marva Rivas',
      },
      {
        id: 288,
        name: 'Irwin Lloyd',
      },
      {
        id: 289,
        name: 'Myra Mathis',
      },
      {
        id: 290,
        name: 'Jones Oliver',
      },
      {
        id: 291,
        name: 'Lolita Blevins',
      },
      {
        id: 292,
        name: 'Baird Lyons',
      },
      {
        id: 293,
        name: 'Alisa Orr',
      },
      {
        id: 294,
        name: 'David Henson',
      },
      {
        id: 295,
        name: 'Angelica Vazquez',
      },
      {
        id: 296,
        name: 'Winnie Brock',
      },
      {
        id: 297,
        name: 'Horn Carpenter',
      },
      {
        id: 298,
        name: 'Norris Carney',
      },
      {
        id: 299,
        name: 'Frieda Hester',
      },
    ],
    greeting: 'Hello, undefined! You have 3 unread messages.',
    favoriteFruit: 'asdf',
  },
  versions: [
    {
      version: 1,
      responseType: 'plain',
      encryptionKid: 'test.common.jwk.v.1',
      resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
      enableAccessCode: true,
      'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
      clientId: 'dtp-dev.pink:1',
      type: 'Client',
      services: {
        'klarna-ref': {
          '@default': ['klarna-payment-settings', 'klarna.sandbox'],
        },
        'tink-ref': {
          '@default': ['tink.sandbox'],
        },
        'kontomatik-ref': {
          '@default': ['kontomatik.sandbox'],
        },
      },
    },
  ],
  resolved: {
    responseType: 'plain',
    encryptionKid: 'test.common.jwk.v.1',
    resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
    enableAccessCode: true,
    'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
    clientId: 'dtp-dev.pink',
    type: 'Client',
    services: {
      klarna: {
        '@default': {
          klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
          klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
          paymentSettings: {
            to: {
              iban: 'DE74202201000050503014',
              holder_name: 'Signicat AS',
              holder_address: {
                street_address: 'Beddingen 16',
                postalcode: '7042',
                city: 'Trondheim',
                country: 'NO',
              },
              bic: 'DNBADEHX',
              bank_name: 'DNB Bank ASA - Filiale Deutschland',
              bank_address: {
                street_address: 'Neuer wall 72',
                postalcode: '20354',
                city: 'Hamburg',
                country: 'DE',
              },
            },
            amount: {
              amount: 1,
              currency: 'EUR',
            },
          },
        },
      },
      tink: {
        '@default': {
          'apiKey-ref-secret': 'tink-api-key/sandbox',
          payment: {
            amount: 0.01,
            currency_id: 'EUR',
            purpose: 'Verification payment',
            recipient_iban: 'NO9098022290150',
            recipient_holder: 'Signicat AS',
            language: 'de',
          },
          skipSenderNameValidation: true,
          shouldMockSenderHolder: true,
          retryAttempts: 1,
        },
      },
      kontomatik: {
        '@default': {
          piiStorage: 'tf-dev-03-kontomatik-pii-storage',
          bankStatementUpload: true,
          providerBaseUrl: 'https://test.api.kontomatik.com',
          providerClientId: 'signicat-test',
          'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
        },
      },
    },
  },
  isValidationSuccess: true,
  validationErrors: [],
  references: [],
  referenceMapping: [
    {
      unresolvedPath: 'current',
      resolvedValue: {
        responseType: 'plain',
        encryptionKid: 'test.common.jwk.v.1',
        resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
        enableAccessCode: true,
        'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
        clientId: 'dtp-dev.pink',
        type: 'Client',
        services: {
          klarna: {
            '@default': {
              klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
              klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
              paymentSettings: {
                to: {
                  iban: 'DE74202201000050503014',
                  holder_name: 'Signicat AS',
                  holder_address: {
                    street_address: 'Beddingen 16',
                    postalcode: '7042',
                    city: 'Trondheim',
                    country: 'NO',
                  },
                  bic: 'DNBADEHX',
                  bank_name: 'DNB Bank ASA - Filiale Deutschland',
                  bank_address: {
                    street_address: 'Neuer wall 72',
                    postalcode: '20354',
                    city: 'Hamburg',
                    country: 'DE',
                  },
                },
                amount: {
                  amount: 1,
                  currency: 'EUR',
                },
              },
            },
          },
          tink: {
            '@default': {
              'apiKey-ref-secret': 'tink-api-key/sandbox',
              payment: {
                amount: 0.01,
                currency_id: 'EUR',
                purpose: 'Verification payment',
                recipient_iban: 'NO9098022290150',
                recipient_holder: 'Signicat AS',
                language: 'de',
              },
              skipSenderNameValidation: true,
              shouldMockSenderHolder: true,
              retryAttempts: 1,
            },
          },
          kontomatik: {
            '@default': {
              piiStorage: 'tf-dev-03-kontomatik-pii-storage',
              bankStatementUpload: true,
              providerBaseUrl: 'https://test.api.kontomatik.com',
              providerClientId: 'signicat-test',
              'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
            },
          },
        },
      },
    },
    {
      unresolvedPath: 'current/services/klarna-ref/@default',
      resolvedValue: {
        klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
        klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
        paymentSettings: {
          to: {
            iban: 'DE74202201000050503014',
            holder_name: 'Signicat AS',
            holder_address: {
              street_address: 'Beddingen 16',
              postalcode: '7042',
              city: 'Trondheim',
              country: 'NO',
            },
            bic: 'DNBADEHX',
            bank_name: 'DNB Bank ASA - Filiale Deutschland',
            bank_address: {
              street_address: 'Neuer wall 72',
              postalcode: '20354',
              city: 'Hamburg',
              country: 'DE',
            },
          },
          amount: {
            amount: 1,
            currency: 'EUR',
          },
        },
      },
    },
    {
      unresolvedPath: 'current/services/tink-ref/@default',
      resolvedValue: {
        'apiKey-ref-secret': 'tink-api-key/sandbox',
        payment: {
          amount: 0.01,
          currency_id: 'EUR',
          purpose: 'Verification payment',
          recipient_iban: 'NO9098022290150',
          recipient_holder: 'Signicat AS',
          language: 'de',
        },
        skipSenderNameValidation: true,
        shouldMockSenderHolder: true,
        retryAttempts: 1,
      },
    },
    {
      unresolvedPath: 'current/services/kontomatik-ref/@default',
      resolvedValue: {
        piiStorage: 'tf-dev-03-kontomatik-pii-storage',
        bankStatementUpload: true,
        providerBaseUrl: 'https://test.api.kontomatik.com',
        providerClientId: 'signicat-test',
        'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
      },
    },
    {
      unresolvedPath: 'current/services/klarna-ref/@default[0]',
      resolvedValue: {
        version: 1,
        paymentSettings: {
          amount: {
            amount: 1,
            currency: 'EUR',
          },
          to: {
            bank_address: {
              country: 'DE',
              street_address: 'Neuer wall 72',
              city: 'Hamburg',
              postalcode: '20354',
            },
            bank_name: 'DNB Bank ASA - Filiale Deutschland',
            holder_address: {
              country: 'NO',
              street_address: 'Beddingen 16',
              city: 'Trondheim',
              postalcode: '7042',
            },
            bic: 'DNBADEHX',
            holder_name: 'Signicat AS',
            iban: 'DE74202201000050503014',
          },
        },
        clientId: 'klarna-payment-settings',
        type: 'Entity',
      },
    },
    {
      unresolvedPath: 'current/services/klarna-ref/@default[1]',
      resolvedValue: {
        klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
        version: 1,
        klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
        clientId: 'klarna.sandbox',
        type: 'Entity',
      },
    },
    {
      unresolvedPath: 'current/services/tink-ref/@default[0]',
      resolvedValue: {
        version: 1,
        retryAttempts: 1,
        'apiKey-ref-secret': 'tink-api-key/sandbox',
        shouldMockSenderHolder: true,
        payment: {
          amount: 0.01,
          language: 'de',
          recipient_holder: 'Signicat AS',
          purpose: 'Verification payment',
          currency_id: 'EUR',
          recipient_iban: 'NO9098022290150',
        },
        skipSenderNameValidation: true,
        clientId: 'tink.sandbox',
        type: 'Entity',
      },
    },
    {
      unresolvedPath: 'current/services/kontomatik-ref/@default[0]',
      resolvedValue: {
        version: 1,
        providerBaseUrl: 'https://test.api.kontomatik.com',
        piiStorage: 'tf-dev-03-kontomatik-pii-storage',
        bankStatementUpload: true,
        'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
        providerClientId: 'signicat-test',
        clientId: 'kontomatik.sandbox',
        type: 'Entity',
      },
    },
  ],
  secretReferences: {
    'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
    'services.tink.@default.apiKey-ref-secret': 'tink-api-key/sandbox',
    'services.kontomatik.@default.providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
  },
}

export const getGroupList = {
  groups: [
    {
      id: 'test-id',
      name: 'TestSet',
    },
    {
      id: 'test-id2',
      name: 'X',
    },
    {
      id: 'test-id3',
      name: 'grupa-testowa',
    },
    {
      id: 'test-id4',
      name: 'Igor-Test-Group',
    },
    {
      id: 'test-id5',
      name: 'test-del2',
    },
  ],
}

export const getGroupDetails = {
  id: 'test-id',
  members: [
    {
      isDeleted: false,
      name: 'Paweł Jurusz',
      userId: '6182389c-ed94-44d5-9f85-9e63add33a38',
    },
    {
      isDeleted: false,
      name: 'User 1',
      userId: '6182389c-ed94-44d5-9f85-9e63add33a38',
    },
    {
      isDeleted: true,
      name: 'User 2',
      userId: '6182389c-ed94-44d5-9f85-9e63add33a38',
    },
    {
      isDeleted: false,
      name: 'User 3',
      userId: '6182389c-ed94-44d5-9f85-9e63add33a38',
    },
    {
      userId: 'aaaa',
    },
  ],
  name: 'test2',
}

export const getPermissions = {
  permissions: [
    {
      resourceType: 'Config',
      resourceName: 'zyx',
      operation: 'Write',
      allowedGroups: [
        {
          id: 'aa',
          name: 'asdfasdf',
        },
      ],
    },
    {
      resourceType: 'Config',
      resourceName: 'xyz',
      operation: 'Write',
      allowedGroups: [
        {
          id: 'aaa',
        },
        {
          id: 'bbb',
          name: 'asdfasdf',
        },
        {
          id: 'ccc',
        },
      ],
    },
    {
      resourceType: 'Config',
      resourceName: 'fooBarBaz',
      operation: 'Write',
      allowedGroups: [
        {
          id: '2ee81a26-08ec-41e4-a65f-768f195982b8',
        },
        {
          id: 'c4bd54aa-2862-4919-8ade-e001883f1591',
          name: 'grupa-testowa',
        },
        {
          id: 'qwerty',
        },
      ],
    },
    {
      resourceType: 'Config',
      resourceName: 'bar',
      operation: 'Test',
      allowedGroups: [],
    },
    {
      resourceType: 'Config',
      resourceName: 'bar',
      operation: 'Write',
      allowedGroups: [],
    },
  ],
}

export const getOncalls = [
  {
    schedule: 'Team Global solutions_schedule',
    participants: ['michal.kozlowski@signicat.com'],
  },
  {
    schedule: 'Global Solutions 2nd line',
    participants: ['pawel.jurusz@signicat.com'],
  },
]

export const capabilityRes = {
  resourceType: 'test',
  resourceName: 'test-id',
  operation: 'all',
  authorized: true,
}
