import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { AboutContainer, SectionTitle, HighlightedText } from '../Settings.styles'
import { StoreContext } from '@/components/App'
import { appMetadata } from '@/constants/appMetadata'

export const About: FC = observer(() => {
  const store = useContext(StoreContext)

  return (
    <AboutContainer>
      <SectionTitle>PinkHub App</SectionTitle>

      <div>
        Version:{' '}
        <HighlightedText onClick={() => store.ModalState.openModal('changelog')}>{appMetadata.version}</HighlightedText>
      </div>
      <div>
        By: <HighlightedText>Pink Angry Snakes</HighlightedText>
      </div>
    </AboutContainer>
  )
})
