export const appMetadata: AppMetadata = {
  version: 'Beta 1.1.2',
  versionIndex: 'beta_1.1.2',
  title: 'PinkHub App',
  description: 'Configuration command center',
  changelog: {
    'beta_1.1.2': [
      {
        title: 'Improved ref preview icon (🔎) in Client tab JSON viewer',
        description:
          'Previously when clicking on Entity clientId ref 🔎 on Client JSON view it would open plain object view tab. Now if the object clientId is valid Entity it will open corresponding Entity tab instead.',
      },
    ],
    'beta_1.1.1': [
      {
        title: 'Fixed missing scroll',
        description: 'Fixed missing scroll in the Tools section when content was overflowing the container.',
      },
    ],
    'beta_1.1.0': [
      {
        title: 'Environment selection',
        description:
          'User is now able to change base API URL to work on different environment than the app is deployed on.',
      },
      {
        title: 'Settings tab',
        description: 'Added new settings and improved layout of the settings tab.',
      },
      {
        title: 'JSON Editor',
        description:
          "Added icon at the beginning of the JSON editor for displaying currentRef value onclick. Additionally now you can use CTRL+A to select whole JSON file event when you're not editing it.",
      },
      {
        title: 'New & adjusted keyboard shortcuts',
        description:
          "We've added new keyboard shortcuts and changed base combo key for MacOS from Cmd to Ctrl due to conflicts with browser/system shortcuts.",
      },
      {
        title: 'UI/CSS improvements',
        description: 'Fixed different spacings in the app, multi-select inputs and improved look of settings tab.',
      },
      {
        title: 'Right sidebar',
        description: 'Improved right sidebar with text wrapping and better responsiveness and reference onclick copy.',
      },
      {
        title: 'This popup!',
        description: 'Implemented this popup with most important insights related to the last release of the app.',
      },
    ],
    'beta_1.0.0': [
      {
        title: 'App Launch',
        description: 'The new SPA version of PinkHub has been launched.',
      },
    ],
  },
}

interface AppMetadata {
  version: string
  versionIndex: string
  title: string
  description: string
  changelog: {
    [key: string]: {
      title: string
      description: string
    }[]
  }
}
