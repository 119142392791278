import { FC, useContext } from 'react'
import { Spacer } from '@styles/generic.styles'
import { Container, Title, HighlightedText, ChangelogBody } from './ModalTypes.styles'
import { ModalFooter } from '@components/Modal/Modal.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '@components/App'
import { Modal } from '@/components/Modal/Modal'
import { appMetadata } from '@/constants/appMetadata'

export const ChangelogModal: FC = observer(() => {
  const store = useContext(StoreContext)

  const close = () => {
    store.ModalState.closeModal('changelog')
    localStorage.setItem('lastChangelogVersion', appMetadata.versionIndex)
  }

  const { version, changelog, versionIndex } = appMetadata
  return (
    <Modal onClose={close} width="800px">
      <Container>
        <Title>
          Changelog - <HighlightedText>{version}</HighlightedText>
        </Title>

        <Spacer size={20} />

        <ChangelogBody>
          {changelog[versionIndex].map((change, index) => (
            <div key={index}>
              <h3>{change.title}</h3>
              <p>{change.description}</p>
            </div>
          ))}
        </ChangelogBody>

        <Spacer size={20} />
      </Container>

      <ModalFooter $justifyContent="center">
        <button onClick={close}>Gotcha</button>
      </ModalFooter>
    </Modal>
  )
})
