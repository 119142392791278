export const STAGES = ['prod', 'local', 'dev', 'dev-02', 'dev-03', 'dev-04', 'dev-05', 'dev-06', 'dev-07'] as const

export type Stage = (typeof STAGES)[number]

const isValidStage = (str: string) => {
  return STAGES?.includes(str as Stage)
}

const getStageFromLocalStorage = () => {
  const localStage = localStorage.getItem('stage')
  return isValidStage(localStage) ? localStage : undefined
}

export const STAGE = getStageFromLocalStorage() || process.env.STAGE || ('prod' as Stage)

const stageApiMap = {
  prod: 'https://pinkhub.io/web-api',
  local: '127.0.0.1:3000',
  dev: 'https://pink-panther.dev-eu.identity.signicat.global/web-api',
  'dev-02': 'https://pink-panther.dev-02-eu.identity.signicat.global/web-api',
  'dev-03': 'https://pink-panther.dev-03-eu.identity.signicat.global/web-api',
  'dev-04': 'https://pink-panther.dev-04-eu.identity.signicat.global/web-api',
  'dev-05': 'https://pink-panther.dev-05-eu.identity.signicat.global/web-api',
  'dev-06': 'https://pink-panther.dev-06-eu.identity.signicat.global/web-api',
  'dev-07': 'https://pink-panther.dev-07-eu.identity.signicat.global/web-api',
}

export const API_URL = stageApiMap[STAGE] || process.env.WEB_API_URL || `${window.origin}/web-api`
